import React from 'react';
import { graphql, Link } from 'gatsby';
import { FaFileDownload } from 'react-icons/fa';

import { Layout, HTML, Icon } from '../components';

const JobPostingPageTemplate = ({ data }) => {
    const { title, date, jobSpec, pdf } = data.markdownRemark.frontmatter;

    return (
        <Layout title={ title }>
            <div className='p-4 lg:px-0 lg:pt-8 lg:pb-20'>
                <div className='w-full lg:w-4/5 mx-auto mb-2 text-3xl lg:text-5xl text-center text-primary font-light'>{ title }</div>
                <div className='w-full mb-2 text-center text-gray-500'>posted on { (new Date(Date.parse(date))).toLocaleString().split(', ').join(" at ") }</div>
                { pdf ? (
                    <div className='w-full mb-4 lg:mb-8 flex flex-row justify-center'>
                        <a href={ pdf } aria-label={ `Download link for job posting '${title}'` } target='_blank' rel='noreferrer'>
                            <div className='flex flex-row items-center text-primary hover:text-secondary transition-all'>
                                <Icon className='mr-2' iconClassName='text-2xl'>
                                    <FaFileDownload/>
                                </Icon>
                                <div className='text-xl'>Download as PDF</div>
                            </div>
                        </a>
                    </div>
                ) : <div className='mb-2 lg:mb-6'/> }
                <div className='w-full md:w-4/5 lg:w-3/5 mx-auto mb-2 lg:mb-4'>
                    <Link to='/careers' className='text-gray-500 hover:text-black transition-all'>{ "<" } <em className='underline'>Browse all Job Postings</em></Link>
                </div>
                <HTML className='w-full md:w-4/5 lg:w-3/5 mx-auto mb-4 lg:mb-8 text-justify text-md lg:text-xl text-dark' html={ jobSpec }/>
            </div>
        </Layout>
    );
};

export default JobPostingPageTemplate;

export const pageQuery = graphql`
    query JobPostingPageTemplateQuery ($id: String!) {
        markdownRemark(id: { eq: $id }) {
            frontmatter {
                title
                date
                jobSpec
                pdf
            }
        }
    }
`;